import { reactive } from "vue"

const toast = reactive({
  message: null,
  style: null,
  visible: false
})

const showToast = (message, style) => {
  toast.message = message
  toast.style = style
  toast.visible = toast.message && toast.style
  setTimeout(() => closeToast(), 4500)
}

const closeToast = () => {
  toast.message = null
  toast.style = null
  toast.visible = false
}

export { toast, showToast, closeToast }
