import TheHeader from "@/components/TheHeader.vue"
import TheSidebar from "@/components/TheSidebar.vue"
import TheBottomBar from "@/components/TheBottomBar.vue"

export default [
  {
    path: "/school",
    name: "School",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/school/HomeView.vue")
    },
    meta: {
      title: "Tableau de bord - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: 2
    }
  },
  {
    path: "/school/students",
    name: "Students",
    redirect: "/school/students/list",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/school/students/IndexView.vue")
    },
    children: [
      {
        path: "/school/students/list",
        name: "Students List",
        meta: {
          primaryTitle: "Vos étudiants",
          subtitle: "Tous vos étudiants",
          displayName: "Étudiants",
          isTab: true
        },
        component: () => import("@/views/school/students/ListView.vue")
      },
      {
        path: "/school/students/pending",
        name: "Students Pending Invitations",
        meta: {
          primaryTitle: "Vos invitations",
          subtitle: "Toutes vos invitations",
          displayName: "Invitations",
          isTab: true
        },
        component: () => import("@/views/school/students/invitations/ListView.vue")
      },
      {
        path: "/school/students/trainings",
        name: "Students Formations",
        meta: {
          primaryTitle: "Vos formations",
          subtitle: "Toutes vos formations",
          displayName: "Formations",
          isTab: true
        },
        component: () => import("@/views/school/students/formations/ListView.vue")
      },
      {
        path: "/school/students/classes",
        name: "Students Classes",
        meta: {
          primaryTitle: "Vos classes",
          subtitle: "Toutes vos classes",
          displayName: "Classes",
          isTab: true
        },
        component: () => import("@/views/school/students/classes/ListView.vue")
      },
      {
        path: "/school/student/:id/:tab",
        name: "student.details",
        meta: {
          primaryTitle: "Vos étudiants",
          subtitle: "Détail d'un étudiant",
          displayName: null,
          isTab: false
        },
        component: () => import("@/views/school/students/RetrieveView.vue")
      }
    ],
    meta: {
      title: "Vos étudiants - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: 2
    }
  },
  {
    path: "/school/partners",
    name: "partners",
    redirect: "/school/partners/list",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/school/partners/IndexView.vue")
    },
    children: [
      {
        path: "/school/partners/list",
        name: "partners.list",
        meta: {
          primaryTitle: "Vos partenaires",
          subtitle: "Tous vos partenaires",
          displayName: "Liste",
          isTab: true
        },
        component: () => import("@/views/school/partners/ListView.vue")
      },
      {
        path: "/school/company/:id/:tab",
        name: "School Partner Profile",
        meta: {
          primaryTitle: "",
          subtitle: "Informations sur l'entreprise",
          displayName: null,
          isTab: false
        },
        component: () => import("@/views/school/partners/RetrieveView.vue")
      },
      /*{
        path: "/school/partners/job-offers",
        name: "partners.job-offers",
        meta: {
          primaryTitle: "Offres d'emploi",
          subtitle: "Toutes vos offres d'emploi",
          displayName: "Offres d'emploi",
          isTab: true
        },
        component: () => import("@/views/school/partners/offers/ListView.vue")
      },*/
      /*{
        path: "/school/partners/job-offers/new",
        name: "partners.job-offers.new",
        meta: {
          primaryTitle: "Offres d'emploi",
          subtitle: "Création d'une nouvelle offre",
          displayName: "Nouvelle offre",
          isTab: true
        },
        component: () => import("@/views/school/partners/offers/CreateView.vue")
      }*/
      /*{
        path: "/school/partners/job-offers/edit/:id",
        name: "partners.job-offers.update",
        meta: {
          primaryTitle: "Offres d'emploi",
          subtitle: "Mise à jour d'une offre",
          displayName: null,
          isTab: false
        },
        component: () => import("@/views/school/partners/offers/EditView.vue")
      },*/
      /*{
        path: "/school/partners/job-offer/:id",
        name: "partners.job-offer",
        meta: {
          primaryTitle: "",
          subtitle: "Visualisation d'une offre",
          displayName: null,
          isTab: false
        },
        component: () => import("@/views/school/partners/offers/RetrieveView.vue")
      }*/
    ],
    meta: {
      title: "Vos partenaires - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: 2
    }
  },
  {
    path: "/school/contracts",
    name: "school.contracts",
    redirect: "/school/contracts/list",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/school/contracts/IndexView.vue")
    },
    children: [
      {
        path: "/school/contracts/list",
        name: "school.contracts.list",
        meta: {
          primaryTitle: "Tous vos contrats",
          subtitle: "Tous vos contrats",
          displayName: "Contrats",
          isTab: true
        },
        component: () => import("@/views/school/contracts/ListView.vue")
      },
      {
        path: "/school/contracts/new/:contract",
        name: "school.contracts.new",
        meta: {
          primaryTitle: "Nouveau contrat",
          subtitle: "Rédaction d'un nouveau contrat",
          displayName: null,
          isTab: false
        },
        component: () => import("@/views/school/contracts/CreateView.vue")
      },
      {
        path: "/school/contract/:id",
        name: "school.contract.details",
        meta: {
          primaryTitle: "Tous vos contrats",
          subtitle: "Détails d'un contrat",
          displayName: null,
          isTab: false
        },
        component: () => import("@/views/school/contracts/RetrieveView.vue")
      },
      {
        path: "/school/contract/edit/:id",
        name: "Contract Edit",
        meta: {
          primaryTitle: "Tous vos contrats",
          subtitle: "Édition d'un contrat",
          displayName: null,
          isTab: false
        },
        component: () => import("@/views/school/contracts/EditView.vue")
      },
      {
        path: "/school/contract/imported/list",
        name: "Contracts Imported List",
        meta: {
          primaryTitle: "Contrats importés",
          subtitle: "Vos contrats importés",
          displayName: "Importés",
          isTab: true
        },
        component: () => import("@/views/school/contracts/imported/ListView.vue")
      },
      {
        path: "/school/contract/archived/list",
        name: "Contracts Archived List",
        meta: {
          primaryTitle: "Contrats archivés",
          subtitle: "Vos contrats archivés",
          displayName: "Archivés",
          isTab: true
        },
        component: () => import("@/views/school/contracts/archived/ListView.vue")
      },
    ],
    meta: {
      title: "Vos contrats - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: 2
    }
  },
  {
    path: "/school/invoicing",
    name: "School Invoicing",
    redirect: "/school/invoicing/invoices",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/school/invoicing/IndexView.vue")
    },
    children: [
      {
        path: "/school/invoicing/invoices",
        name: "School Invoices",
        meta: {
          primaryTitle: "Facturation",
          subtitle: "Vos échéanciers",
          isTab: true
        },
        component: () => import("@/views/school/invoicing/ListView.vue")
      },
      {
        path: "/school/invoicing/invoice/:id",
        name: "School Invoice Details",
        meta: {
          primaryTitle: "Facturation",
          subtitle: "Détails d'un échéancier",
          isTab: true
        },
        component: () => import("@/views/school/invoicing/RetrieveView.vue")
      },
    ],
    meta: {
      title: "Facturation - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: 2
    }
  },
  {
    path: "/school/education",
    name: "School Education",
    redirect: "/school/education/calendar",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/school/education/IndexView.vue")
    },
    children: [
      {
        path: "/school/education/calendar",
        name: "School Education Calendar",
        meta: {
          primaryTitle: "Pédagogie",
          subtitle: "Calendrier de formation",
          isTab: true
        },
        component: () => import("@/views/school/education/calendar/ListView.vue")
      },
      {
        path: "/school/education/trainers",
        name: "School Trainers",
        meta: {
          primaryTitle: "Pédagogie",
          subtitle: "Formateurs",
          isTab: true
        },
        component: () => import("@/views/school/education/trainers/ListView.vue")
      },
      {
        path: "/school/education/attendance",
        name: "School Attendance",
        meta: {
          primaryTitle: "Pédagogie",
          subtitle: "Feuilles de présence",
          isTab: true
        },
        component: () => import("@/views/school/education/attendance/ListView.vue")
      },
      {
        path: "/school/education/attendance/:id",
        name: "School Attendance Details",
        meta: {
          primaryTitle: "Pédagogie",
          subtitle: "Feuilles de présence",
          isTab: false
        },
        component: () => import("@/views/school/education/attendance/RetrieveView.vue")
      },
    ],
    meta: {
      title: "Pédagogie - TU COMMENCES DEMAIN",
      requireLogin: true,
      accessibleBy: 2
    }
  },
  {
    path: "/school/settings",
    name: "school.settings",
    components: {
      header: TheHeader,
      sidebar: TheSidebar,
      bottombar: TheBottomBar,
      default: () => import("@/views/school/SettingsView.vue")
    },
    meta: {
      title: "Paramètres - TU COMMENCES DEMAIN",
      primaryTitle: "Paramètres",
      subtitle: "Information",
      requireLogin: true,
      accessibleBy: 2
    }
  },
]