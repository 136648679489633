import { ref } from "vue"
import { defineStore } from "pinia"
import useAxios from "@/composables/useAxios"

export const useMain = defineStore("main", () => {
    const mainBasicData = ref(JSON.parse(localStorage.getItem("mainBasicData")) || null)
    const offlineBasicData = ref(JSON.parse(localStorage.getItem("offlineBasicData")) || null)

    function $reset() {
        useMain().$patch({
            mainBasicData: null,
            offlineBasicData: null
        })
    }

    async function completeContract(userData) {
        const response = await useAxios.post(`/administrative/cerfa/completion`, userData)
        return response.data.state === "success"
    }

    async function fetchMainBasicData() {
        const response = await useAxios.get(`/user/basic-data`)
        localStorage.setItem("mainBasicData", JSON.stringify(response.data))
        mainBasicData.value = response.data
    }

    async function fetchOfflineBasicData() {
        const response = await useAxios.get(`/school/administrative-lo-basic-data`)
        localStorage.setItem("offlineBasicData", JSON.stringify(response.data))
        offlineBasicData.value = response.data
    }

    async function validateConvention(userData) {
      const response = await useAxios.post(`/administrative/training-convention/validation`, userData)
      return response.data.state === "success"
    }

    return {
        $reset,

        mainBasicData,
        offlineBasicData,

        completeContract,
        fetchMainBasicData,
        fetchOfflineBasicData,
        validateConvention
    }
})

