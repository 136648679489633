import { createRouter, createWebHistory } from "vue-router"
import { useAuth } from "@/stores/auth"
import { useMain } from "@/stores/main"
import { useStudentMain } from "@/stores/students/main"
import { useCompanyMain } from "@/stores/companies/main"
import { useSchoolMain } from "@/stores/school/main"

import studentRoutes from "./studentRoutes"
import companyRoutes from "./companyRoutes"
import schoolRoutes from "./schoolRoutes"
import NotFoundView from "@/views/NotFoundView.vue"

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
      ...studentRoutes,
      ...companyRoutes,
      ...schoolRoutes,
    {
      path: "/onboarding/:token",
      name: "onboarding",
      components: {
        default: () => import("@/views/OnboardingView.vue")
      },
      meta: {
        title: "Bienvenue - TU COMMENCES DEMAIN",
        disabledIfLogged: true,
        accessibleBy: null
      }
    },
    {
      path: "/validation/:token",
      name: "validation",
      components: {
        default: () => import("@/views/CustomerValidationView.vue")
      },
      meta: {
        title: "Validation - TU COMMENCES DEMAIN",
        disabledIfLogged: true,
        accessibleBy: null
      }
    },
    {
      path: "/school/partnership/:token",
      name: "schoolPartnership",
      components: {
        default: () => import("@/views/SchoolPartnershipView.vue")
      },
      meta: {
        title: "Invitation - TU COMMENCES DEMAIN",
        disabledIfLogged: true,
        accessibleBy: null,
        public: true
      }
    },
    {
      path: "/contract-completion/:token",
      name: "contract-completion",
      components: {
        default: () => import("@/views/ContractCompanyCompletionView.vue")
      },
      meta: {
        title: "Complétion d'un contrat - TU COMMENCES DEMAIN",
        disabledIfLogged: false,
        accessibleBy: null,
        public: true
      }
    },
    {
      path: "/convention-validation/:token",
      name: "convention-validation",
      components: {
        default: () => import("@/views/ConventionValidationView.vue")
      },
      meta: {
        title: "Validation de la convention - TU COMMENCES DEMAIN",
        disabledIfLogged: true,
        accessibleBy: null,
        public: true
      }
    },
    {
      path: "/signed",
      name: "signed",
      components: {
        default: () => import("@/views/ContractSignRedirect.vue")
      },
      meta: {
        title: "Signature d'un contrat - TU COMMENCES DEMAIN",
        disabledIfLogged: false,
        accessibleBy: null,
        public: true
      }
    },
    {
      path: "/login",
      name: "login",
      components: {
        default: () => import("@/views/LoginView.vue")
      },
      meta: {
        title: "Connexion - TU COMMENCES DEMAIN",
        disabledIfLogged: true,
        accessibleBy: null
      }
    },
    {
      path: "/forgot",
      name: "forgot",
      components: {
        default: () => import("@/views/ForgotPasswordView.vue")
      },
      meta: {
        title: "Mot de passe oublié - TU COMMENCES DEMAIN",
        disabledIfLogged: true,
        accessibleBy: null
      }
    },
    {
      path: "/reset/:token",
      name: "reset",
      components: {
        default: () => import("@/views/ResetPasswordView.vue")
      },
      meta: {
        title: "Réinitialisation de mot de passe - TU COMMENCES DEMAIN",
        disabledIfLogged: true,
        accessibleBy: null
      }
    },
    {
      path: "/sandbox",
      name: "Sandbox",
      components: {
        default: () => import('@/views/SandboxView.vue'),
      },
      beforeEnter: (to, from, next) => {
        if (import.meta.env.VITE_APP_NODE_ENV === "development") {
          next()
        } else {
          next("/")
        }
      },
      meta: {
        title: "Bac à sable - TU COMMENCES DEMAIN",
        requireLogin: false,
        disableIfLogged: false,
        accessibleBy: null
      }
    },
    {
      path: "/:pathMatch(.*)*",
      name: "404notFound",
      components: {
        default: NotFoundView
      },
      meta: {
        title: "Erreur 404 - TU COMMENCES DEMAIN",
        accessibleBy: null
      }
    }
  ]
})

router.beforeEach(async (to, from) => {
  const authStore = useAuth()
  const mainStore = useMain()
  const studentMainStore = useStudentMain()
  const companyMainStore = useCompanyMain()
  const schoolMainStore = useSchoolMain()
  const homeName = authStore.accountType === 0 ? 'Student Home' : authStore.accountType === 1 ? 'company' : authStore.accountType === 2 ? 'School' : 'login'
  document.title = to.meta.title

  /* Logged ? Fetch data depending on account type & local storage */
  if (authStore.getIsAuthenticated) {
    if (!localStorage.getItem("user")) {
      await authStore.fetchUserAction()
    }

    if (!localStorage.getItem("mainBasicData")) {
      await mainStore.fetchMainBasicData()
    }

    if ((authStore.accountType === 1 || authStore.accountType === 2) && (!localStorage.getItem("customer") || !localStorage.getItem("organization"))) {
      await authStore.fetchCustomerAction()
      await authStore.fetchOrganizationAction()
    }

    if (authStore.accountType === 0) {
      if (!localStorage.getItem('studentMainBasicData')) {
        await studentMainStore.fetchBasicData()
      }
    }

    if (authStore.accountType === 1) {
      if (!localStorage.getItem('companyMainBasicData')) {
        await companyMainStore.fetchBasicData()
      }
    }

    if (authStore.accountType === 2) {
      if (!localStorage.getItem('schoolAdministrativeBasicData')) {
        await schoolMainStore.fetchAdministrativeBasicData()
      }
      if (!localStorage.getItem('schoolMainBasicData')) {
        await schoolMainStore.fetchBasicData()
      }
    }
  }

  if (to.meta.public) {
    return true
  }

  if (to.matched.some(record => record.meta.isDisabled)) {
    return { name: homeName }
  }

  if (!authStore.getIsAuthenticated && to.matched.some(record => record.meta.requireLogin)) {
    return { name: "login", query: { to: to.path } }
  }

  if (authStore.getIsAuthenticated && to.matched.some(record => record.meta.disabledIfLogged)) {
    return { name: homeName }
  }

  if (authStore.getIsAuthenticated && to.meta.accessibleBy !== authStore.accountType) {
    return { name: homeName }
  }

  return true
})

export default router
