<script setup>

</script>

<template>
  <div class="flex flex-col justify-center items-center gap-6 h-full">
    <h1 class="text-8xl">404</h1>
    <h2 class="text-2xl">La page introuvable</h2>
    <router-link to="/" class="text-blue-500 underline">Retourner à l'accueil</router-link>
  </div>
</template>