import { ref } from "vue"
import { defineStore } from "pinia"
import useAxios from "@/composables/useAxios"

export const useCompanyMain = defineStore("companyMain", () => {
    const basicData = ref(JSON.parse(localStorage.getItem("companyMainBasicData")) || null)
    const partners = ref([])
    const profile = ref()
    const stats = ref()

    function $reset() {
        useCompanyMain().$patch({
            basicData: null,
            partners: null,
            profile: null,
            stats: null
        })
    }

    async function editProfile(userData) {
        const response = await useAxios.patch(`/company/companies/update-profile/`, userData)
        profile.value.name = response.data.company.denomination
        useCompanyMain().$patch({
            profile: response.data
        })
    }

    async function fetchBasicData() {
        const response = await useAxios.get(`/company/basic-data`)
        localStorage.setItem("companyMainBasicData", JSON.stringify(response.data))
        useCompanyMain().$patch({
            basicData: response.data
        })
    }

    async function fetchPartners() {
        useCompanyMain().$patch({ partners: [] })
        const response = await useAxios.get(`/customer/partnerships`)
        response.data.results.forEach((item) => {
            return partners.value.push(item.school)
        })
    }

    async function fetchProfile() {
        const response = await useAxios.get(`/company/profile`)
        useCompanyMain().$patch({
            profile: response.data.customer
        })
    }

    async function fetchStats() {
        const response = await useAxios.get(`/company/dashboard-statistics`)
        useCompanyMain().$patch({
            stats: response.data.statistics
        })
    }

    return {
        $reset,

        basicData,
        partners,
        profile,
        stats,

        editProfile,
        fetchBasicData,
        fetchPartners,
        fetchProfile,
        fetchStats

    }
})
