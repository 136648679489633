import { computed, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { defineStore, getActivePinia } from "pinia"
import * as Sentry from "@sentry/vue"
import useAxios from "@/composables/useAxios"

export const useAuth = defineStore("auth", () => {
    const route = useRoute()
    const router = useRouter()
    
    const accessToken = ref(localStorage.getItem('accessToken') || null)
    const accountType = ref(parseInt(localStorage.getItem('accountType')) ?? null)
    const customer = ref(JSON.parse(localStorage.getItem("customer")) || null)
    const integrations = ref(JSON.parse(localStorage.getItem("integrations")) || null)
    const intercomToken = ref(localStorage.getItem("intercomToken") || null)
    const organization = ref(JSON.parse(localStorage.getItem("organization")) || null)
    const refreshToken = ref(localStorage.getItem('refreshToken') || null)
    const user = ref(JSON.parse(localStorage.getItem('user')) || null)

    const getIsAuthenticated = computed(() => !!accessToken.value)

    async function $reset() {
        useAuth().$patch({
            accessToken: null,
            accountType: null,
            customer: null,
            organization: null,
            refreshToken: null,
            user: null
        })
        localStorage.clear()

    }

    async function fetchCustomerAction() {
        const response = await useAxios.get(`/customer/member-details`)
        localStorage.setItem("customer", JSON.stringify(response.data))
        useAuth().$patch({
            customer: response.data
        })
    }

    async function fetchIntegrations() {
        const response = await useAxios.get(`/user/atc-integrations`)
        localStorage.setItem("integrations", JSON.stringify(response.data.integrations))
        useAuth().$patch({
            integrations: response.data.integrations
        })
    }

    async function fetchOrganizationAction() {
        const response = await useAxios.get(`/customer/organization-details`)
        localStorage.setItem("organization", JSON.stringify(response.data.customer))
        useAuth().$patch({
            organization: response.data.customer
        })
    }

    async function fetchUserAction() {
        const response = await useAxios.get(`/user/account`)
        localStorage.setItem("user", JSON.stringify(response.data))
        useAuth().$patch({
            user: response.data
        })
    }

    async function loginAction(userData) {
        const response = await useAxios.post(`/user/login`, userData)
        localStorage.setItem("accessToken", response.data.access)
        localStorage.setItem("accountType", response.data.account_type)
        localStorage.setItem("intercomToken", response.data.intercom_token)
        localStorage.setItem("refreshToken", response.data.refresh)
        useAuth().$patch({
            accessToken: response.data.access,
            accountType: parseInt(response.data.account_type),
            intercomToken: response.data.intercom_token,
            refreshToken: response.data.refresh
        })
        await fetchUserAction()
        switch (accountType.value) {
            case 0:
                return router.push(route.query.to || '/')
            case 1:
                await fetchCustomerAction()
                await fetchOrganizationAction()
                return router.push(route.query.to || '/company')
            case 2:
                await fetchCustomerAction()
                await fetchOrganizationAction()
                await fetchIntegrations()
                return router.push(route.query.to || '/school')
        }
    }

    async function logoutAction() {
        accessToken.value = null
        router.push("/")
        await useAxios.post(`/user/logout`, { "refresh": refreshToken.value })
        await getActivePinia()._s.forEach(store => {
            if (store.$reset) {
                store.$reset()
            }
        })
        Sentry.setUser(null)
    }

    async function refreshTokenAction() {
        const response = await useAxios.post(`/user/refresh`, { "refresh": refreshToken.value })
        localStorage.setItem("accessToken", response.data.access)
        useAuth().$patch({
            accessToken: response.data.access
        })
    }

    async function updateProfilePicture(userData) {
        const response = await useAxios.patch(`/user/update-profile-picture`, userData)
        user.value.profile_picture_url = response.data.profile_picture_url
        localStorage.setItem("user", JSON.stringify(user.value))
    }

    return {
        $reset,

        accessToken,
        accountType,
        customer,
        integrations,
        intercomToken,
        organization,
        refreshToken,
        user,

        getIsAuthenticated,

        fetchCustomerAction,
        fetchOrganizationAction,
        fetchUserAction,
        loginAction,
        logoutAction,
        refreshTokenAction,
        updateProfilePicture
    }
})
