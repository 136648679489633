import { ref } from "vue"
import { defineStore } from "pinia"
import useAxios from "@/composables/useAxios"

export const useStudentMain = defineStore("studentMain", () => {
    const basicData = ref(JSON.parse(localStorage.getItem("studentMainBasicData")) || null)
    const profile = ref()
    const stats = ref()

    function $reset() {
        useStudentMain().$patch({
            basicData: null,
            profile: null,
            stats: null
        })
    }

    async function addDocument(userData) {
        const response = await useAxios.post(`/student/documents/`, userData)
        profile.value.student_documents.push(response.data.document)
    }

    async function addPreference(userData) {
        const response = await useAxios.post(`/student/search-preferences/`, userData)
        profile.value.search_preferences.push(response.data.search_preference)
    }

    async function deletePreference(id){
        await useAxios.delete(`/student/search-preferences/${id}`)
            const index = profile.value.search_preferences.findIndex(preference => preference.id === id)
            if (index > -1) {
                profile.value.search_preferences.splice(index, 1)
            }
    }

    async function editPreference(userData) {
        const response = await useAxios.post(`student/toggle-search-preference`, userData)
        const index = profile.value.search_preferences.findIndex(preference => preference.id === userData.search_preference_id)
        if (index > -1) {
            profile.value.search_preferences[index] = response.data.search_preference
        }
    }

    async function editProfile(userData) {
        const response = await useAxios.patch(`/student/students/update-profile/`, userData)
        useStudentMain().$patch({
            profile: response.data.student
        })
    }

    async function fetchBasicData() {
        const response = await useAxios.get(`/student/basic-data`)
        localStorage.setItem("studentMainBasicData", JSON.stringify(response.data))
        useStudentMain().$patch({
            basicData: response.data
        })
    }

    async function fetchProfile() {
        const response = await useAxios.get(`/student/profile`)
        useStudentMain().$patch({
            profile: response.data.student
        })
    }

    async function fetchStats() {
        const response = await useAxios.get(`/student/dashboard-statistics`)
        useStudentMain().$patch({
            stats: response.data.statistics
        })
    }


    return {
        $reset,

        basicData,
        profile,
        stats,

        addDocument,
        addPreference,
        deletePreference,
        editPreference,
        editProfile,
        fetchBasicData,
        fetchProfile,
        fetchStats
    }
})
